import { Config } from "@breezerr";
import { getColor } from "@breezerr/utils";
import type { Components, MenuItemProps } from "@mui/material";

export const MuiSliderTheme: Components = {
    MuiSlider: {
        defaultProps: {
            
        },
        styleOverrides: {
            root: {
                
            },
            track: {
                height: '.9rem',
                borderRadius: '4.5rem'
            },
            rail: {
                height: '.9rem',
                borderRadius: '4.5rem',
                backgroundColor: getColor('grey', 400)
            },
            thumb: {
                width: '3rem',
                height: '3rem',
                // marginTop: '-1.1rem',
                // marginLeft: '-1.6rem',
                borderWidth: '.6rem',
                borderStyle: 'solid',
                borderColor: getColor('primary', 800),
                backgroundColor: '#fff'
            }
        }
    }
};