import { createPattern, escapeSelector } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern('place-content-(?:(start|center|end|between|around|evenly|stretch))', {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, value] = props;
    // console.log(props);
    const selector = escapeSelector(className);


    enum ValueMap {
        'start' = 'start',
        'center' = 'center',
        'end' = 'end',
        'stretch' = 'stretch',
        'between' = 'space-between',
        'around' = 'space-around',
        'evenly' = 'space-evenly'
    }
    

    const rule = {
        [selector]: {
            placeContent: ValueMap[value]
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };