import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern(
    `(uppercase|lowercase|capitalize|normal-case)`, 
    {breakpoints: true}
);

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, value] = props;
    // console.log(props);
    
    const selector = escapeSelector(className);
    

    enum ValueMap {
        'uppercase' = 'uppercase',
        'lowercase' = 'lowercase',
        'capitalize' = 'capitalize',
        'normal-case' = 'none'
    }
    

    const rule = {
        [selector]: {
            textTransform: ValueMap[value],
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };