import React from 'react';
import Cookies from 'js-cookie';



type ReferralParamsContextModel = {
    [key in MarketingParamsType]: string;
} & {
    updateParam: (param: MarketingParamsType, value: string) => void
} & {
    setParam: (param: string, value: string) => void;
};

const ReferralParamsContext = React.createContext<ReferralParamsContextModel | null>(null);


export const ReferralParamsWrapper = ({ children }: { children: React.ReactNode }) => {
    const [params, setParams] = React.useState<ReferralParamsContextModel | null>(null);


    // On Mount
    React.useEffect(() => {
        if ( typeof window === 'undefined' ) return;


        const location = window.location;
        const queryParamsString = location.search;

        const _params = {} as ReferralParamsContextModel;
        const URLParams = new URLSearchParams(queryParamsString);


        /**
         * Create a params object - accounting for the aliases to set cookies before setting the final state.
         */
        URLParams.forEach((_value, key) => {
            // Check for cookeie names in the map
            const found = QueryToCookieMap.find(item => {
                return item.q === key || item.aliases?.includes(key);
            });

            // Sanitize value
            const value = sanitizeValue(_value);

            // Skip adding to _params list if the value is falsey
            if ( !value ) return;

            if ( found ) {
                _params[found.c] = value;
            } else {
                _params[key as MarketingParamsType] = value;
            }
        });


        /**
         * Check for Traking Id change
         * 
         * If there's a tune tracking id that doesn't match the current cookie value, we need to clear all the previous marketing values 
         * (last click wins)
         */
        const trakingParams = QueryToCookieMap.find(item => item.q === 'transaction_id');
        const queryTrakingId = URLParams.get(trakingParams?.q || '');
        const cookieTrackingId = Cookies.get(trakingParams?.c || '');

        if ( queryTrakingId && queryTrakingId !== cookieTrackingId ) {
            console.log('Tune ID has changed. Clearing all cookies');
            clearAllCookies();
        }


        /**
         * Set cookies.
         * 
         * Cookies must be set before updating params state so that components that want to perform updates on query params 
         * can use "useReferralParams" custom hook to make sure cookies were set.
         */
        // console.log('Setting referral cookies', _params);
        Object.keys(_params).forEach(name => {
            const value = _params[name as MarketingParamsType];
            
            setCookie(name, value);
        });
        

        // console.log('Referral cookies are set');
        // console.log(`Getting all cookies for testing:`, Cookies.get());
        React.startTransition(() => {
            setParams(() => {
                if (queryTrakingId && queryTrakingId !== cookieTrackingId) {
                    // Reset context params state to current query params
                    return _params;
                } else {
                    /**
                     * Set params form cookies for the context
                     */
                    const coockieValues = {} as Record<keyof typeof MarketingTermMap, string>;
                    CookiesValueMap.forEach(item => {
                        // console.log(item.v, ':', 'Skip?', !sanitizeValue(item.v));
                        
                        // Skip undefined values or values that are "unsanitary" :) 
                        if (item.v === undefined || !sanitizeValue(item.v)) return;

                        coockieValues[item.c] = item.v;
                    });
                    // Update context params state by merging context params with current query params
                    return Object.assign({}, coockieValues, _params);
                }
            });
        });
        
    }, []);


    /**
     * Updates a referral param based on param name both in cookies and in React Context
     * @param param one of the available referral params
     */
    const updateParam = (param: MarketingParamsType, value: string) => {
        // Update cookies
        setCookie(param, value);

        // Update Context
        setParams((prev) => {
            const newState: ReferralParamsContextModel = prev || {} as ReferralParamsContextModel;
            return {
                ...newState,
                [param]: value
            }
        });
    };

    /**
     * Add a new param not from the known list of params
     */
    const setParam = (param: string, value: string) => {
        // Update cookies
        setCookie(param, value);

        // Update Context
        setParams((prev) => {
            const newState: ReferralParamsContextModel = prev || {} as ReferralParamsContextModel;
            return {
                ...newState,
                [param]: value
            }
        });
    };


    // Functions
    const cookieDomain = React.useMemo(() => {
        let cookieDomain = `.${(location.host.indexOf('leverageplanning.com') !== -1 ? location.host.replace('app.', '') : location.host).replace('www.', '')}`;
        if (cookieDomain.indexOf("localhost") >= 0) {
            cookieDomain = "localhost";
        }
        return cookieDomain;
    }, []);

    const sanitizeValue = (input: string | undefined): string => {
        return input ? input.replace(/{.+}/g, '') : '';
    };


    const setCookie = (name: string, value: string, expires: number = 30) => {
        Cookies.set(name, value, { domain: cookieDomain, expires: expires });
    };

    const getCookie = (name: string) => {
        Cookies.get(name);
    };

    const clearAllCookies = () => {
        QueryToCookieMap.map(item => {
            Cookies.remove(item.c, { domain: cookieDomain });
        });
    };


    // Memoed Context
    const memoed = React.useMemo(() => {
        return params ? { ...params, updateParam, setParam } : null;
    }, [params]);

    return <ReferralParamsContext.Provider value={memoed}>
        { children }
    </ReferralParamsContext.Provider>
};

/**
 * Custom hook for easy access to the referral params context.
 * @returns Memoized object of referral cookies 
 */
export const useReferralParams = () => {
    return React.useContext(ReferralParamsContext);
};




type MarketingParamsType = 
    'referredByCode' |
    'marketingMedium' |
    'marketingSource' |
    'marketingCampaign' |
    'marketingContent' |
    'marketingTerm' |
    'marketingGClid' | 
    'tuneTransactionId' |
    'tuneOfferId' |
    'tunePartnerId' |
    'aff_sub1' |
    'aff_sub2' |
    'aff_sub3' |
    'aff_sub4' |
    'aff_sub5' |
    'tuneSource' |
    'marketingCoBrandingId' |
    'marketingCarrierLogosOption' |
    'referralFactoryCode'; 

const MarketingTermMap: Record<string, { c: MarketingParamsType; aliases?: string[] }> = {
    'rc': { c: 'referredByCode' },
    'utm_medium': { c: 'marketingMedium' },
    'utm_source': { c: 'marketingSource' },
    'utm_campaign': { c: 'marketingCampaign' },
    'utm_content': { c: 'marketingContent' },
    'utm_term': { c: 'marketingTerm' },
    'gclid': { c: 'marketingGClid' },
    'transaction_id': { c: 'tuneTransactionId' },
    'offer_id': { c: 'tuneOfferId' },
    'aff_id': { c: 'tunePartnerId' },
    'aff_sub1': { c: 'aff_sub1' },
    'aff_sub2': { c: 'aff_sub2' },
    'aff_sub3': { c: 'aff_sub3' },
    'aff_sub4': { c: 'aff_sub4' },
    'aff_sub5': { c: 'aff_sub5' },
    'source': { c: 'tuneSource' },
    'cbid': { c: 'marketingCoBrandingId', aliases: ['cb_id'] },
    'logos': { c: 'marketingCarrierLogosOption' },
    'code': { c: 'referralFactoryCode' }
};


const QueryToCookieMap = Object.keys(MarketingTermMap).map((key) => {
    const item = MarketingTermMap[key as keyof typeof MarketingTermMap];
    return { 
        q: key, 
        c: item.c, 
        aliases: item.aliases
    } as {
        q: keyof typeof MarketingTermMap;
        c: MarketingParamsType;
        aliases?: string[];
    }
});

const CookiesValueMap = Object.keys(MarketingTermMap).map((key) => {
    const item = MarketingTermMap[key as keyof typeof MarketingTermMap];
    
    return {
        c: item.c,
        v: Cookies.get(item.c)
    }
});