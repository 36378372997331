import React from 'react';
import { Config } from '@breezerr';


type BreakpointKeys = keyof typeof Config.breakpoints;

type ContextType = {
    breakpoint: BreakpointKeys | null;
    isMobile: boolean | null;
    isTablet: boolean | null;
};

const defaultContext: ContextType = {
    breakpoint: null,
    isMobile: null,
    isTablet: null
};

const IsBreakpointContext = React.createContext<ContextType>(defaultContext);

const breakpoints = Object.keys(Config.breakpoints).map((key) => {
    return {
        breakpoint: key as BreakpointKeys,
        media: Config.breakpoints[key as BreakpointKeys].media
    }
}).filter(item => item.breakpoint).sort((a, b) => b.media - a.media);



export const BreakpointWrapper = ({children}: {children: React.ReactNode}) => {
    const [context, setContext] = React.useState<ContextType>(defaultContext);
    const firstRenderRef = React.useRef(true);


    const handleWindowResize = () => {
        // const isMobile = window.outerWidth <= Config.breakpoints['sm'].media;
        // const isTablet = window.outerWidth <= Config.breakpoints['md'].media && window.outerWidth > Config.breakpoints['smd'].media;
        
        let currentBreakpoint: BreakpointKeys = '';
        
        for (let index = 0; index < breakpoints.length; index++) {
            const item = breakpoints[index];

            if (window.outerWidth >= item.media) {  
                currentBreakpoint = item.breakpoint;
                break;
            }
        }

        const isMobile = ['', 'xxs', 'xs', 'sm'].includes(currentBreakpoint);
        const isTablet = ['smd', 'md'].includes(currentBreakpoint);

        React.startTransition(() => {
            setContext({
                breakpoint: currentBreakpoint,
                isMobile: isMobile,
                isTablet: isTablet
            });
        });
    };

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleWindowResize, { passive: true });

            handleWindowResize();

            // Cleanup
            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        }
    }, []);


    const memoed = React.useMemo(() => {
        return context;
    }, [context.breakpoint, context.isMobile]);

    return <IsBreakpointContext.Provider value={memoed}>{children}</IsBreakpointContext.Provider>
};


export const useBreakpoint = () => {
    return React.useContext(IsBreakpointContext);
};