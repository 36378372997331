import React, { AnchorHTMLAttributes } from "react";
import { Link } from 'gatsby';

type TextLinkProps = {
    href: string;
    activeClassName?: string;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const TextLink = React.forwardRef((props: TextLinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) => {
    const { href, activeClassName, ...other } = props;

    const isHttpLink = href?.match(/^http/);
    const isAppPath = href?.indexOf('/app/') > -1;
    const isMailOrPhone = href?.indexOf('tel:') > -1 || href?.indexOf('mailto:') > -1;
    const isHash = href?.match(/^\#|\/\#/);
    // const isExternal = !!isHttpLink ? (props.href?.match(new RegExp(`${baseUrl}|${baseUrl.replace('www.', '')}`)) ? false : true) : false; 
    const isExternal = !!isHttpLink ? true : false;

    return (
        isAppPath || isExternal || isHash || isMailOrPhone ? (
            <a {...other} href={href} ref={ref} data-external>{ props.children }</a>
        ) : (
            <Link {...other} to={href} data-internal activeClassName={activeClassName}>{ props.children }</Link>
        )
    )
});

