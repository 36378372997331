import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern(
    `(?:(hover|active|focus|visited)\:){0,1}(underline|line-through|no-underline)(\!){0,1}`, 
    {breakpoints: true}
);

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, state, value, important] = props;
    // console.log(props);
    
    const selector = escapeSelector(className);
    

    enum ValueMap {
        'underline' = 'underline',
        'line-through' = 'line-through',
        'no-underline' = 'none'
    }
    

    const rule = {
        [`${selector}${state ? `:${state}` : ''}`]: {
            textDecoration: `${ValueMap[value]}${important ? ' !important' : ''}`,
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };