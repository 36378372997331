import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern(`column-(count|width|gap){0,1}-(${numValuePattern}){0,1}((?:${unitPattern})|auto|unset){0,1}`, {breakpoints: true});


// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, property, rawValue, unit] = props; // unit can also be "auto" | "unset"
    // console.log(props);
    const selector = escapeSelector(className);

    // send null to calculateUnitValue to omit numeric value and use "auto" | "unset" as value as CSS propery value
    const value = rawValue ? parseFloat(rawValue) : null;
    const calculated = `${calculateUnitValue(value, unit)}`;
    
    const properyMap = {
        count: 'columnCount',
        width: 'columnWidth',
        gap: 'columnGap'
    };
    
    const rule = {
        [selector]: {
            [properyMap[property]]: calculated
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };