import { createPattern, escapeSelector } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern('of(-(?:x|y)){0,1}-(hidden|visible|scroll|auto|unset)(\!){0,1}', {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, property, value, important] = props;
    // console.log(className, breakpoint, value);
    const selector = escapeSelector(className);

    const rule = {
        [selector]: {
            [`overflow${property}`]: `${value}${important ? ' !important' : ''}`,
        }
    };

    return {
        breakpoint,
        rule
    };
}

export default { pattern, rules };