import { createPattern, escapeSelector } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern(`grow-([0-9]|(?:[1-9][0-9]*)+)$`, { breakpoints: true });

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, value] = props;
    // console.log(props);
    const selector = escapeSelector(className);

    const rule = {
        [selector]: {
            flexGrow: value
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };