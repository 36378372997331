import { createPattern, escapeSelector } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern('(col|row)-span-([1-9][0-9]*|full)', {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, property, value] = props;
    // console.log(props);
    const selector = escapeSelector(className);
    const properyValue = value === 'full' ? '1 / -1' : `span ${value} / span ${value}`;

    enum PropertyMap {
        'col' = 'grid-column',
        'row' = 'grid-row'
    }
    

    const rule = {
        [selector]: {
            [PropertyMap[property]]: properyValue
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };