import { Config } from "@breezerr";
import { color, getColor } from "@breezerr/utils";
import type { Components } from "@mui/material";

export const MuiInputTheme: Components = {
    MuiOutlinedInput: {
        defaultProps: {
            
        },
        styleOverrides: {
            root: {
                fontSize: '1.6rem',
                borderRadius: '0.5rem',
                '&.Mui-disabled': {
                    
                }
            },
            input: {
                padding: '1.8rem 1.4rem',
                lineHeight: '1.5rem',
                height: '1.5rem'
            }
        }
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                height: '5.5rem',
                '&.Mui-disabled fieldset': {
                    backgroundColor: color('grey:200'),
                    borderColor: `${color('grey:400')} !important`,
                },
                '&.Mui-error input': {
                    color: color('error:500')
                },
                '& fieldset': {
                    borderColor: `${color('grey:800')}`,
                }
            },
            multiline: {
                height: 'unset',
                '& textarea': {
                    padding: 0,
                    lineHeight: '2.2rem',
                }
            }
        }
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                fontSize: '1.6rem',
                marginTop: '0rem',
                color: color('grey:900'),
                '&.Mui-disabled': {
                    color: color('grey:600')
                },
            },
            shrink: {
                color: color('primary:800'),
                top: '-1px'
            }
        }
    },
    MuiFormLabel: {
        styleOverrides: {
            filled: {
                color: color('primary:800'),
                '&:not(.Mui-error) + .MuiOutlinedInput-root:not(.Mui-disabled) fieldset': {
                    backgroundColor: 'transparent',
                    borderColor: `${color('primary:800')}`,
                    borderWidth: '2px'
                }
            },

        }
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: { 
                position: 'absolute',
                bottom: '-2rem',
                fontSize: '1.2rem' 
            }
        }
    },
    MuiTextField: {
        defaultProps: {
            SelectProps: {
                MenuProps: {
                    PaperProps: {
                        elevation: 4
                    }
                }
            }
        },
    },
    MuiSelect: {
        styleOverrides: {
            select: {
                display: 'flex',
                alignItems: 'center'
            }
        }
    },
    
};