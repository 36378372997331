import { breakpointKeys, calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType } from '../types';
import config from '../config';
import { BreakpointKeys } from '@breezerr/breakpoints';

// Pattern
export const pattern = createPattern(`(?:(min|max)-){0,1}(w|h)-(${numValuePattern}){0,1}((?:${unitPattern})|auto|unset|initial|revert){0,1}(?:\\(container:(${breakpointKeys.filter(bp => !!bp).join('|')})\\)){0,1}(?:__([^!]+)){0,1}(\!){0,1}`, {breakpoints: true});

// Resolver
export const rules = (props: any): ClassObjectType => {
    const [className, breakpoint, minmax, property, rawValue, unit, breakpointSize, rawChildSelector, important] = props; // unit can also be "auto" | "unset"
    // console.log(props);
    const selector = escapeSelector(className);
    const childSelector = escapeSelector(rawChildSelector, false);

    // send null to calculateUnitValue to omit numeric value and use "auto" | "unset" as value as CSS propery value
    const value = rawValue ? parseFloat(rawValue) : null; 

    const PropertyMap = {
        'w': 'width',
        'h': 'height'
    }

    type PropertyMapKeys = keyof typeof PropertyMap;

    const breakpointValue = breakpointSize ? config.breakpoints[breakpointSize as BreakpointKeys].container : null;

    const rule = {
        [`${selector}${childSelector ? ` ${childSelector}` : ''}`]: {
            [`${minmax}${minmax ? '-' : ''}${PropertyMap[property as PropertyMapKeys]}`]: breakpointValue ? `${typeof breakpointValue === 'number' ? `${breakpointValue}px` : breakpointValue}` : `${calculateUnitValue(value, unit)}${important ? ' !important' : ''}`
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };