import { createPattern, escapeSelector } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern('place-(?:(self|items)-(auto|start|center|end|stretch|between|around|evenly))', {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, property, value] = props;
    // console.log(props);
    const selector = escapeSelector(className);

    enum PropertyMap {
        'items' = 'place-items',
        'self' = 'place-self'
    }

    enum ValueMap {
        'auto' = 'auto',
        'start' = 'start',
        'center' = 'center',
        'end' = 'end',
        'stretch' = 'stetch',
        'between' = 'space-between',
        'around' = 'space-around',
        'evenly' = 'space-evenly'
    }
    

    const rule = {
        [selector]: {
            [PropertyMap[property]]: value
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };