import config from '../config';
import { calculateUnitValue, createPattern, escapeSelector, getColor, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType, ColorObjectType, ConfigType } from '../types';

// Pattern
export const pattern = createPattern(
    `(?:(hover|active|focus|visited)\:){0,1}(border|border-top|border-rigt|border-bottom|border-left|text|bg)-(?:(on)-){0,1}color-([a-z]+)(?:\:{0,1}([\-\+]){0,1}(${numValuePattern})){0,1}(\!){0,1}`, 
    {breakpoints: true}
);

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, state, property, on, color, operator, rawHue, important] = props;
    // console.log(props);
    
    const selector = escapeSelector(className);
    const hue = !isNaN(parseInt(rawHue)) ? parseInt(rawHue) : null;
    

    enum PropertyMap {
        'bg' = 'background-color',
        'text' = 'color',
        'border' = 'border-color',
        'border-top' = 'border-top-color',
        'border-right' = 'border-right-color',
        'border-bottom' = 'border-bottom-color',
        'border-left' = 'border-left-color'
    }
    

    const rule = {
        [`${selector}${state ? `:${state}` : ''}`]: {
            [PropertyMap[property]]: `${getColor(color, hue, operator, !!on)}${important ? ' !important' : ''}`,
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };