import { createPattern, escapeSelector } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern('p-([a-z]+)(\!){0,1}', {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, value, important] = props;
    // console.log(className, breakpoint, value);
    const selector = escapeSelector(className);

    const rule = {
        [selector]: {
            position: `${value}${important ? ' !important' : ''}`,
        }
    };

    return {
        breakpoint,
        rule
    };
}

export default { pattern, rules };