import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern(
    `text-of-(clip|ellipsis)`, 
    {breakpoints: true}
);

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, value] = props;
    // console.log(props);
    
    const selector = escapeSelector(className);
    

    enum ValueMap {
        'clip' = 'clip',
        'ellipsis' = 'ellipsis',
    }
    

    const rule = {
        [selector]: {
            textOverflow: ValueMap[value],
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };