import { calculateUnitValue, createPattern, escapeSelector } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern(`text-([0-9]{3})(?:__([^!]+)){0,1}(\!){0,1}`, {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, value, rawChildSelector, important] = props;
    // console.log(props);
    
    const selector = escapeSelector(className);
    const childSelector = escapeSelector(rawChildSelector, false);

    const rule = {
        [`${selector}${childSelector ? ` ${childSelector}` : ''}`]: {
            fontWeight: `${calculateUnitValue(value, '')}${important ? ' !important' : ''}`, // unit is concatinated to the value: send empty string
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };