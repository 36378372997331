import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern(`p(x|y|t|r|b|l){0,1}-(${numValuePattern})(${unitPattern})(\!){0,1}`, {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, property, rawValue, unit, important] = props; // unit can also be "auto" | "unset"
    // console.log(props);
    const selector = escapeSelector(className);

    // send null to calculateUnitValue to omit numeric value and use "auto" | "unset" as value as CSS propery value
    const value = rawValue ? parseFloat(rawValue) : null;

    const calculated = `${calculateUnitValue(value, unit)}${important ? ' !important' : ''}`;

    const PropertyValueMap = {
        't': { paddingTop: calculated },
        'r': { paddingRight: calculated },
        'b': { paddingBottom: calculated },
        'l': { paddingLeft: calculated },
        'x': { paddingLeft: calculated, paddingRight: calculated },
        'y': { paddingTop: calculated, paddingBottom: calculated },
        '':  { padding: calculated },
        
    }

    const rule = {
        [selector]: PropertyValueMap[property]
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };