declare global {
  interface Window {
    dataLayer: Array<object>;
  }
}

import React from 'react';
import { Script } from 'gatsby';
import { env } from '@lr-env';

export const GTM = () => {
    const gtmStarted = React.useRef(false);

    React.useEffect(() => {
        if ( gtmStarted.current === true ) return;
        
        if ( typeof window !== 'undefined') {
            gtmStarted.current = true;
            
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({'gtm.start': new Date().getTime(), event:'gtm.js'});
            console.log('gtm.start');
        }
    }, []);

    return null;
};

export const GTMScript = () => {
    return <Script async src={`https://www.googletagmanager.com/gtm.js?id=${env.GTM_ID}`} strategy="idle" />;
};


// (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
// })(window,document,'script','dataLayer','${env.GTM_ID}');