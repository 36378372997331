import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern(`radius-(?:(top|bottom)-){0,1}(?:(left|right)-){0,1}(${numValuePattern})(${unitPattern})`, {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, y, x, rawValue, unit] = props;
    // console.log(props);
    
    const selector = escapeSelector(className);
    const value = parseFloat(rawValue);
    

    const PropertyMap = {
        '-': 'border',
        'top-left': 'border-top-left',
        'top-right': 'border-top-right',
        'bottom-left': 'border-bottom-left',
        'bottom-right': 'border-bottom-right',
    }
    

    const rule = {
        [selector]: {
            [`${PropertyMap[`${y}-${x}`]}-radius`]: calculateUnitValue(value, unit),
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };