import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern(`spread-(x|r|l)-(${numValuePattern})(${unitPattern})`, {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, direction, rawValue, unit] = props; // unit can also be "auto" | "unset"
    // console.log(props);
    const selector = escapeSelector(className);

    // send null to calculateUnitValue to omit numeric value and use "auto" | "unset" as value as CSS propery value
    const value = rawValue ? parseFloat(rawValue) : null;

    const calculated = calculateUnitValue(value, unit);

    const common = {
        display: 'block',
        content: '""',
        position: 'absolute',
        background: 'inherit',
        width: calculated,
        height: '100%',
        top: 0,
        transform: 'none'
    }

    const DirectionValueMap = {
        'r': { [`${selector}::after`]: { ...common, left: '100%' } },
        'l': { [`${selector}::before`]: { ...common, right: '100%' } },
        'x': { [`${selector}::before`]: { ...common, right: '100%' }, [`${selector}::after`]: { ...common, left: '100%' } },
    }

    const rule = DirectionValueMap[direction];

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };