import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern(`(?:(hover|active|focus|visited)\:){0,1}(-){0,1}(translate|scale|rotate)-(?:(x|y|z)-){0,1}(${numValuePattern}){0,1}((?:${unitPattern})){0,1}`, {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, state, operator, property, direction, rawValue, unit] = props; // unit can also be "auto" | "unset"
    // console.log(props);
    const selector = escapeSelector(className);

    // send null to calculateUnitValue to omit numeric value and use "auto" | "unset" as value as CSS propery value
    const value = rawValue ? parseFloat(rawValue) : null;

    
    const calculated = `${operator}${calculateUnitValue(value, property === 'scale' ? null : unit)}`;

    const Map = {
        '': `${property}(${calculated})`,
        'x': `${property}X(${calculated})`,
        'y': `${property}Y(${calculated})`,
        'Z': `${property}Z(${calculated})`
    };

    const rule = {
        [`${selector}${state ? `:${state}` : ''}`]: {
            transform: Map[direction]
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };