import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern(
    `(?:(hover|active|focus|visited)\:){0,1}opacity-([0-1](?:\.[0-9]{1,2}){0,1})(\!){0,1}`,
    {breakpoints: true}
);

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, state, rawValue, important] = props;
    // console.log(props);
    
    const selector = escapeSelector(className);
    const value = parseFloat(rawValue);
    

    const rule = {
        [`${selector}${state ? `:${state}` : ''}`]: {
            opacity: `${value}${important ? ' !important' : ''}`,
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };