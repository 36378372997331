import { Config } from "@breezerr";
import type { Components, MenuItemProps } from "@mui/material";

export const MuiBackdropTheme: Components = {
    MuiBackdrop: {
        defaultProps: {
            
        },
        styleOverrides: {
            root: {
                backgroundColor: `rgba(17, 22, 26, .95)`
            },
            invisible: {
                backgroundColor: 'transparent'
            }
        }
    }
};