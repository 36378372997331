import config from './config'; 
import { ConfigBreakpointType } from './types';

export type BreakpointKeys = keyof typeof config.breakpoints;

const defaultBreakpoints: any = {
    '': { media: 0, container: 300, base: 10 }, // mandatory for no media query selectors
};

export const breakpoints: typeof config.breakpoints = Object.assign(defaultBreakpoints, config.breakpoints);

export const mediaUp = (breakpoint: BreakpointKeys): string | null => {
    if ( breakpoint.indexOf('^') > -1  && breakpoints[breakpoint].height ) {
        return `@media (min-width: ${breakpoints[breakpoint].media}px) and (min-height: ${breakpoints[breakpoint].height}px)`;
    }
    if( breakpoint && breakpoints[breakpoint] ) {
        return `@media (min-width: ${breakpoints[breakpoint].media}px)`;
    }
    return null;
};


export const mediaUpStyleAttr = (breakpoint: BreakpointKeys): string => {
    if ( breakpoint.indexOf('^') > -1  && breakpoints[breakpoint].height ) {
        return `(min-width: ${breakpoints[breakpoint].media}px) and (min-height: ${breakpoints[breakpoint].height}px)`;
    }
    if( breakpoint && breakpoints[breakpoint] ) {
        return `(min-width: ${breakpoints[breakpoint].media}px)`;
    }
    return '';
};