import type { Components, MenuItemProps } from "@mui/material";

import { Config } from "@breezerr";
import { color } from "@breezerr/utils";

export const MuiFabTheme: Components = {
    MuiFab: {
        defaultProps: {
            
        },
        styleOverrides: {
            root: {
                boxShadow: `0 .1rem .2rem 0 ${color('shadow:700')},
                            0 0.5rem 1rem 0 ${color('shadow:600')},
                            0 1rem 4rem 0 ${color('shadow:500')}`,
                '&:hover': {
                    boxShadow: `0 .1rem .2rem 0 ${color('shadow:700')},
                                0 0.5rem 1rem 0 ${color('shadow:600')},
                                0 1rem 4rem 0 ${color('shadow:500')}`,
                },
                '&:active': {
                    boxShadow: `0 .1rem .3rem 0 ${color('shadow:700')},
                                0 1rem 1.5rem 0 ${color('shadow:600')},
                                0 2.5rem 8rem 0 ${color('shadow:500')}`,
                }
            },

        }
    }
};