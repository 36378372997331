import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern(`(-){0,1}m(x|y|t|r|b|l){0,1}-(${numValuePattern}){0,1}((?:${unitPattern})|auto|unset)(?:__([^!]+)){0,1}(\!){0,1}`, {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, operator, property, rawValue, unit, rawChildSelector, important] = props; // unit can also be "auto" | "unset"
    
    // console.log(props);

    const selector = escapeSelector(className);
    const childSelector = escapeSelector(rawChildSelector, false);

    // send null to calculateUnitValue to omit numeric value and use "auto" | "unset" as value as CSS propery value
    const value = rawValue ? parseFloat(rawValue) : null;

    
    const calculated = `${operator}${calculateUnitValue(value, unit)}${important ? ' !important' : ''}`;

    const PropertyValueMap = {
        't': { marginTop: calculated },
        'r': { marginRight: calculated },
        'b': { marginBottom: calculated },
        'l': { marginLeft: calculated },
        'x': { marginLeft: calculated, marginRight: calculated },
        'y': { marginTop: calculated, marginBottom: calculated },
        '':  { margin: calculated },
        
    }

    const rule = {
        [`${selector}${childSelector ? ` ${childSelector}` : ''}`]: PropertyValueMap[property]
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };