import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern(`(-){0,1}(top|right|bottom|left){0,1}-(${numValuePattern}){0,1}((?:${unitPattern})|auto|unset)`, {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, operator, property, rawValue, unit] = props; // unit can also be "auto" | "unset"
    // console.log(props);
    const selector = escapeSelector(className);

    // send null to calculateUnitValue to omit numeric value and use "auto" | "unset" as value as CSS propery value
    const value = rawValue ? parseFloat(rawValue) : null;

    
    const calculated = `${operator}${calculateUnitValue(value, unit)}`;

    const rule = {
        [selector]: {
            [property]: calculated
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };