import container from './modules/container';
// import row from './modules/row';

// Grid
import grid from './modules/grid/grid';
import gap from './modules/grid/gap';
import flow from './modules/grid/flow';
import span from './modules/grid/span';
import startEnd from './modules/grid/start-end';

// Box Align
import placeItemsSelf from './modules/box-align/place-items-self';
import placeContent from './modules/box-align/place-content';
import alignJustifyItems from './modules/box-align/items';
import alignJustifyContent from './modules/box-align/content';
import alignJustifySelf from './modules/box-align/self';

// Flexbox
import flexDirection from './modules/flexbox/flex-direction';
import flexWrap from './modules/flexbox/flex-wrap';
import order from './modules/flexbox/order';
import grow from './modules/flexbox/flex-grow';

// Utils
import display from './modules/display';
import border from './modules/border';
import radius from './modules/radius';
import opacity from './modules/opacity';
import zIndex from './modules/z-index';
import overflow from './modules/overflow';
import boxSizing from './modules/box-sizing';
import transition from './modules/transition';
import spread from './modules/spread';
import spreadRadius from './modules/spread-radius';
import pointerEvents from './modules/pointer-events';
import cursor from './modules/cursor';

import position from './modules/position';
import positioning from './modules/positioning';
import widthHeight from './modules/width-height';
import padding from './modules/padding';
import margin from './modules/margin';
import transform from './modules/transform';

import columns from './modules/columns';

// Typography
import lineLight from './modules/typography/line-height';
import letterSpacing from './modules/typography/letter-spacing';
import weight from './modules/typography/font-weight';
import align from './modules/typography/text-align';
import textStyle from './modules/typography/text-style';
import textTransform from './modules/typography/text-transform';
import textDecoration from './modules/typography/text-decoration';
import textSize from './modules/typography/text-size';
import whitespace from './modules/typography/whitespace';
import textOverflow from './modules/typography/text-overflow';

// Colors
import colors from './modules/colors';

// Classname regex to Function mapping
export const map = {
    [container.pattern]: container.rules,
    // [row.pattern]: row.rules,

    // Utils
    [display.pattern]: display.rules,
    [border.pattern]: border.rules,
    [radius.pattern]: radius.rules,
    [opacity.pattern]: opacity.rules,
    [zIndex.pattern]: zIndex.rules,
    [overflow.pattern]: overflow.rules,
    [boxSizing.pattern]: boxSizing.rules,
    [transition.pattern]: transition.rules,
    [spread.pattern]: spread.rules,
    [spreadRadius.pattern]: spreadRadius.rules,
    [pointerEvents.pattern]: pointerEvents.rules,
    [cursor.pattern]: cursor.rules,

    // Grid
    [grid.pattern]: grid.rules,
    [gap.pattern]: gap.rules,
    [flow.pattern]: flow.rules,
    [span.pattern]: span.rules,
    [startEnd.pattern]: startEnd.rules,

    //Box Align
    [placeItemsSelf.pattern]: placeItemsSelf.rules,
    [placeContent.pattern]: placeContent.rules,
    [alignJustifyItems.pattern]: alignJustifyItems.rules,
    [alignJustifyContent.pattern]: alignJustifyContent.rules,
    [alignJustifySelf.pattern]: alignJustifySelf.rules,

    // Flexbox
    [flexDirection.pattern]: flexDirection.rules,
    [flexWrap.pattern]: flexWrap.rules,
    [order.pattern]: order.rules,
    [grow.pattern]: grow.rules,

    // Positioning, Size & Spacing
    [position.pattern]: position.rules,
    [positioning.pattern]: positioning.rules,
    [widthHeight.pattern]: widthHeight.rules,
    [padding.pattern]: padding.rules,
    [margin.pattern]: margin.rules,
    [transform.pattern]: transform.rules,

    // Typography
    [lineLight.pattern]: lineLight.rules,
    [letterSpacing.pattern]: letterSpacing.rules,
    [weight.pattern]: weight.rules,
    [align.pattern]: align.rules,
    [textStyle.pattern]: textStyle.rules,
    [textTransform.pattern]: textTransform.rules,
    [textDecoration.pattern]: textDecoration.rules,
    [textSize.pattern]: textSize.rules,
    [whitespace.pattern]: whitespace.rules,
    [textOverflow.pattern]: textOverflow.rules,
    
    // Colors
    [colors.pattern]: colors.rules,

    // Misc
    [columns.pattern]: columns.rules,
    
};

// console.log(map);