import { createPattern, escapeSelector } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern('(col|row)-(start|end)-([1-9][0-9]*|auto)', {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, property, position, value] = props;
    // console.log(props);
    const selector = escapeSelector(className);

    enum PropertyMap {
        'col' = 'grid-column',
        'row' = 'grid-row'
    }
    

    const rule = {
        [selector]: {
            [`${PropertyMap[property]}-${position}`]: value
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };