import React, { useEffect } from 'react';

import config from './config';
export const Config = config;

import { jss, SheetsRegistry } from 'react-jss';

import { common } from './common';
import { map } from './map';

import { ClassObjectType } from './types';
import { BreakpointKeys, breakpoints, mediaUp, mediaUpStyleAttr } from './breakpoints';
import { StyleSheet } from 'jss';
import { IS_DEVELOP } from '@lr-env';


/**
 * Sheets
 */
const sheets = new SheetsRegistry();

// Create sheets for each breakpoint
Object.keys(breakpoints).forEach((breakpoint, index) => {
    const media = mediaUpStyleAttr(breakpoint as BreakpointKeys);

    const sheet = jss.createStyleSheet({}, {
        meta: breakpoint || '',
        media,
        index,
    });
    
    sheets.add(sheet);
    sheet.attach();
});





type BreezerrContextModel = {
    sheets: SheetsRegistry;
    // useClasses: (classNames: Array<string>, prefix?: string) => Array<string>;
    // addClassObjects(items: ClassObjectType | Array<ClassObjectType>, prefix?: string);
};

const BreezerrContext = React.createContext<BreezerrContextModel>({} as BreezerrContextModel);

export const BreezerrWrapper = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {

    // On Mount
    useEffect(() => {
        // Remove SSG styles
        if (typeof window !== 'undefined') {
            const ssgStyles = window.document.querySelectorAll('[data-breezerr-ssg-jss]');
            if (ssgStyles) {
                ssgStyles.forEach(style => {
                    style?.parentNode?.removeChild(style);
                });
            }
        }
    }, []);

    // const memoed = React.callba(() => {
    //     return {
    //         sheets,
    //     }
    // }, [sheets]);

    return <BreezerrContext.Provider value={ { sheets } }>
        <BreezerrCommonStyles />
        { children }
    </BreezerrContext.Provider>
};

export const BreezerrStyleTags = () => {
    return sheets.registry.map((sheet) => {

        return <style
            data-breezerr-ssg-jss
            // data-breakpoint={sheet.options.meta || 'no-breakpoint'}
            key={ sheet.options.meta }
            media={ sheet.options.media }
            dangerouslySetInnerHTML={{ __html: sheet.toString({ format: IS_DEVELOP ? true : false }) }
        }
        />
    });
};

export const BreezerrCommonStyles = () => {
    if (sheets.registry?.length) {
        addClassObjects(common, '');
    } else {
        console.warn('Common Breezerr styles should be used inside the BreezerrWrapper commponent.');
    }

    return null;
};

export const useClass = (className: string, prefix: string = Config.prefix) => {
    const classNames = className
        .trim()
        .replace(/\s+/, ' ')
        .split(' ')
        .filter(className => !!className)
        .filter((value, index, self) => {
            const isUnique: boolean = self.indexOf(value) === index;
            if (!isUnique) {
                console.warn(`Duplicate className "${value}"`);
            }
            return isUnique;
        });
    
    return useClasses(classNames, prefix).join(' ');
};


function useClasses(classNames: Array<string>, prefix: string = Config.prefix): Array<string> {
    const matchedClassNames: string[] = [];

    classNames.forEach(className => {
        let mached = false;

        Object.keys(map).forEach(pattern => {
            const matches = new RegExp(pattern).exec(className);
            // console.log(matches,pattern, className);

            if (matches) {
                const classObject: ClassObjectType | Array<ClassObjectType> = map[pattern](matches.map(match => match || ''));

                matchedClassNames.push(`${prefix ? `${prefix}-` : ''}${className}`);
                addClassObjects(classObject, prefix);

                mached = true;
            }
        });

        // Warn about unmatching class names
        if (!mached) {
            console.warn('Breezerr:', `ClassName "${className}" does not match to any pattern. Will not be used!`, `Classes:`, classNames);
        }
    });

    return matchedClassNames;
};


export function addClassObjects(items: ClassObjectType | Array<ClassObjectType>, prefix: string = Config.prefix) {
    if (Array.isArray(items)) {
        items.forEach(item => {
            addRule(item, prefix)
        });
    } else {
        addRule(items, prefix);
    }
}


function addRule(item: ClassObjectType, prefix: string = Config.prefix) {
    for (const _selector in item.rule) {
        const selector = `${prefix && _selector.match(/^\./) ? `.${prefix}-${_selector.replace(/^\./, '')}` : _selector}`;
        const sheet = sheets.registry.find(sheet => sheet.options.meta === item.breakpoint) as StyleSheet;

        if (!sheet) {
            console.warn(`No breakpoint found with name "${item.breakpoint}" for selector "${_selector.replace('\\', '').replace(/^\./, '')}".`);
            return;
        }

        const rule = sheet.getRule(selector);

        if (!rule && item.rule[_selector]) {
            sheet.addRule(selector, item.rule[_selector], {
                selector: `${selector}`
            });
        }
    }
}


// export const mergeClasses = (...args: string[]) => {
//     console.log(...args);
    
//     // const mergedClasses = [...propsClasses, ...defaultClasses].filter((value, index, self) => self.indexOf(value) === index);
// };