import { Config } from "@breezerr";
import type { Components } from "@mui/material";
import config from "@breezerr/config";

export const MuiRadioTheme: Components = {
    MuiRadio: {
        defaultProps: {
            size: 'medium'
        },
        styleOverrides: {
            root: {
                '& .MuiSvgIcon-root': {
                        fontSize: 20,
                        color: config.colors.primary[800][0]
                    },
                '& + .MuiFormControlLabel-label': {
                    marginTop: '0.8rem',
                    fontSize: '1.5rem'
                },
                '&.Mui-disabled': {
                    '& .MuiSvgIcon-root': {
                        color: config.colors.grey[700][0]
                    },
                    '& + .MuiFormControlLabel-label': {
                        color: `${config.colors.grey[700][0]} !important`
                    }
                }
            },
        }
    }
};