import { createPattern, escapeSelector } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern('grid-(cols|rows)-((?:[1-9][0-9]*|none)+)', {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, property, value] = props;
    
    const selector = escapeSelector(className);

    enum PropertyMap {
        'cols' = 'gridTemplateColumns',
        'rows' = 'gridTemplateRows'
    }

    const rule = {
        [selector]: {
            [PropertyMap[property]]: `repeat(${value}, minmax(0, 1fr));`,
        }
    };

    // console.log(props, rule);

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };