import { Config } from "@breezerr";
import { color } from "@breezerr/utils";
import type { Components } from "@mui/material";

export const MuiPaperTheme: Components = {
    MuiPaper: {
        defaultProps: {
            elevation: 4
        },
        styleOverrides: {
            root: {
                display: 'inline-flex',
                boxSizing: 'border-box',
            },
            elevation1: {
                boxShadow: `0 .1rem .1rem 0 ${color('shadow:700')},
                            0 0.15rem 0.3rem 0 ${color('shadow:600')},
                            0 0.3rem 1.5rem 0 ${color('shadow:500')}`
            },
            elevation2: {
                boxShadow: `0 .1rem .2rem 0 ${color('shadow:700')},
                            0 0.3rem 0.4rem 0 ${color('shadow:600')},
                            0 0.6rem 2.5rem 0 ${color('shadow:500')}`
            },
            elevation3: {
                boxShadow: `0 .1rem .2rem 0 ${color('shadow:700')},
                            0 0.5rem 1rem 0 ${color('shadow:600')},
                            0 1rem 4rem 0 ${color('shadow:500')}`
            },
            elevation4: {
                boxShadow: `0 .1rem .3rem 0 ${color('shadow:700')},
                            0 1rem 1.5rem 0 ${color('shadow:600')},
                            0 2.5rem 8rem 0 ${color('shadow:500')}`
            }
        }
    }
};