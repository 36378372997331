import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern(`(-){0,1}ls-(${numValuePattern})(${unitPattern})(\!){0,1}`, {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, operator, rawValue, unit, important] = props;
    // console.log(props);
    
    const selector = escapeSelector(className);
    const value = parseFloat(rawValue);
    

    const rule = {
        [selector]: {
            letterSpacing: `${operator}${calculateUnitValue(value, unit)}${important ? ' !important' : ''}`,
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };