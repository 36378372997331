import React, { ReactElement, ReactNode } from 'react';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
// import { cache } from '@emotion/css';

import { ThemeProvider } from '@mui/material/styles';
import { MuiTheme } from './theme';


export const createEmotionCache = () => {
  return createCache({ key: 'css', prepend: true });
}

export const cache = createEmotionCache();

export const MuiWrapper = ({ children }: { children: ReactElement | ReactElement[] | ReactNode | ReactNode[] }) => {
    // const brzEmotionCache = createCache({ key: 'css', prepend: true })
    // const isSSR = typeof window === 'undefined' ? true : false;
    // const insertionPoint = !isSSR ? document.querySelector('[data-emotion-insertion-point]') as HTMLElement : undefined;

    return (
        <CacheProvider value={cache}>
            <ThemeProvider theme={MuiTheme}>
                { children }
            </ThemeProvider>
        </CacheProvider>
    );
};
