import { createPattern, escapeSelector } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern(
        '(justify|align)-content-(?:(start|center|end|between|around|evenly|stretch))(\!){0,1}', 
        {breakpoints: true
    });

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, property, value, important] = props;
    // console.log(props);
    const selector = escapeSelector(className);


    enum ValueMap {
        'start' = 'flex-start',
        'center' = 'center',
        'end' = 'flex-end',
        'stretch' = 'stretch',
        'between' = 'space-between',
        'around' = 'space-around',
        'evenly' = 'space-evenly'
    }
    

    const rule = {
        [selector]: {
            [`${property}-content`]: `${ValueMap[value]}${important ? ' !important' : ''}`
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };