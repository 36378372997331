import React, { ReactNode } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

// Utils
import { useReferralParams } from './referral.helper';

// Types
export type ProductTypes = 'di' | 'ltc' | 'annuity';


type PartnerDataModel = {
    source: string;
    name: string;
    phone: string | null;
    logo: string;
};

type CarrierDataModel = {
    id: number;
    name: string;
    htmlName?: string;
    products: Array<ProductTypes>;
};

type PartnerQueryModel = {
    json: {
        carriers: Array<CarrierDataModel>;
        partners: Array<PartnerDataModel>;
    }
};

type PartnerContextModel = {
    carrier: CarrierDataModel | null;
    partner: PartnerDataModel | null;
    // setPartner: React.Dispatch<React.SetStateAction<PartnerDataModel | null>>;
    // updatePartnerData: (prop: keyof PartnerDataModel, value: any) => void;
};

const CoBrandingContext = React.createContext({} as PartnerContextModel)


export const CoBrandingContextWrapper = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { json } = useStaticQuery<PartnerQueryModel>(CarriersAndPartnersQuery);
    // const [product, setProduct] = React.useState<ProductTypes | null>(null);

    const [cbid, setCbid] = React.useState<string | null>(null);
    const [source, setSource] = React.useState<string | null>(null);


    // Cookies
    const referral = useReferralParams();    


    const carrier = React.useMemo<CarrierDataModel | null>(() => {        
        if ( cbid ) {
            return json?.carriers?.find(c => c.id === parseInt(cbid)) || null;
        }
        return null;
    }, [cbid]);


    const partner = React.useMemo<PartnerDataModel | null>(() => {        
        if ( source ) {
            return json?.partners?.find(p => p.source === source) || null;
        }
        return null;
    }, [source]);

    


    // On marketing "cbid" change
    React.useEffect(() => {
        const coBrandingId = referral?.marketingCoBrandingId;

        if ( typeof window !== 'undefined' && coBrandingId && coBrandingId !== cbid ) {
            setCbid(coBrandingId);
        }
    }, [referral?.marketingCoBrandingId]);



    // On marketing "source" change
    React.useEffect(() => {
        const tuneSource = referral?.tuneSource;

        if ( typeof window !== 'undefined' && tuneSource && tuneSource !== source ) {
            setSource(tuneSource);
        }
    }, [referral?.tuneSource]);

    return (
        <CoBrandingContext.Provider value={{
            carrier,
            partner
        }}>
            { children }
        </CoBrandingContext.Provider>
    )
};

export const useCoBranding = () => React.useContext(CoBrandingContext);

export const useCarrierByProduct = (product: ProductTypes | null) => {
    const context = React.useContext(CoBrandingContext);

    return {
        ...(product ? (() => {            
            if ( context.carrier?.products.includes(product) ) {
                return { carrier: context.carrier }
            }
            return null;
        })() : {})
    }
};



const CarriersAndPartnersQuery = graphql`
    query PartnersQuery {
        json: partnersJson {
            carriers {
                id
                name
                htmlName
                products
            }
            partners {
                source
                name
                phone
                logo
            }
        }
    }
`;