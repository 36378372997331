import { Config } from "@breezerr";
import { color } from "@breezerr/utils";
import type { Components, MenuItemProps } from "@mui/material";

export const MuiTooltipTheme: Components = {
    MuiTooltip: {
        defaultProps: {
            
        },
        styleOverrides: {
            tooltip: {
                backgroundColor: 'white',
                color: color('primary:800'),
                maxWidth: '42rem',
                borderRadius: '1rem',
                fontSize: '1.4rem',
                fontWeight: 400,
                letterSpacing: '0.05rem',
                padding: '2rem 2rem',
                boxShadow: `0 .1rem .2rem 0 ${color('shadow:700')},
                            0 0.5rem 1rem 0 ${color('shadow:600')},
                            0 1rem 4rem 0 ${color('shadow:500')}`
            },
            touch: {
                maxWidth: '90vw',
                width: '90vw',
                fontSize: '1.3rem',
                lineHeight: '2.2rem',
                fontWeight: 400,
                letterSpacing: '0.05rem'
            },
            arrow: {
                color: 'white',
                width: '1.5rem',
                height: '1.5rem',
            }
        }
    }
};