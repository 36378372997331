import config from '../config';

import { BreakpointKeys, breakpoints } from '../breakpoints';
import { ClassObjectType } from '../types';
import { createPattern, escapeSelector } from '../utils';

export const pattern = createPattern('(container)');

export const rules = (props: any): Array<ClassObjectType> => {
    const [className] = props;
    const selector = escapeSelector(className);


    // Return an array of ClassObjects for each breakpoint
    return Object.keys(breakpoints).map((br) => {
        const breakpoint = br as BreakpointKeys;
        return {
            breakpoint,
            rule: {
                [selector]: {
                    // position: 'relative',
                    width: '100%',
                    // paddingRight: `${config.gutter / 2}${config.unit}`,
                    // paddingLeft: `${config.gutter / 2}${config.unit}`,
                    marginRight: 'auto',
                    marginLeft: 'auto', 
                    boxSizing: 'border-box',
                    maxWidth: (() => {
                        const w = config.breakpoints[breakpoint].container;

                        if (typeof w === 'string') {
                            return w;
                        }
                        if ( typeof w === 'number' ) {
                            return `${w / 10}rem`;
                        }
                    })()
                }
            }
        }
    }); ;
};

export default { pattern, rules };