import { createPattern, escapeSelector } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern(
        '(justify|align)-self-(?:(start|center|end|stretch))', 
        {breakpoints: true
    });

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, property, value] = props;
    // console.log(props);
    const selector = escapeSelector(className);


    const ValueMap = {
        'auto': 'auto',
        'start': property === 'align' ? 'flex-start' : 'start',
        'center': 'center',
        'end': property === 'align' ? 'flex-end' : 'end',
        'stretch': 'stretch'
    }
    

    const rule = {
        [selector]: {
            [`${property}-self`]: ValueMap[value]
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };