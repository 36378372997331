import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern(`spread-radius-(${numValuePattern})(${unitPattern})`, {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, rawValue, unit] = props; // unit can also be "auto" | "unset"
    // console.log(props);
    const selector = escapeSelector(className);

    // send null to calculateUnitValue to omit numeric value and use "auto" | "unset" as value as CSS propery value
    const value = rawValue ? parseFloat(rawValue) : null;
    const calculated = calculateUnitValue(value, unit);    

    const rule = {
        [`${selector}::before`]: { borderTopLeftRadius: calculated, borderBottomLeftRadius: calculated },
        [`${selector}::after`]: { borderTopRightRadius: calculated, borderBottomRightRadius: calculated }
    }

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };