exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-annuity-get-rates-index-tsx": () => import("./../../../src/pages/annuity/get-rates/index.tsx" /* webpackChunkName: "component---src-pages-annuity-get-rates-index-tsx" */),
  "component---src-pages-docs-widget-[product]-[pid]-index-tsx": () => import("./../../../src/pages/docs/widget/[product]/[pid]/index.tsx" /* webpackChunkName: "component---src-pages-docs-widget-[product]-[pid]-index-tsx" */),
  "component---src-pages-docs-widget-index-tsx": () => import("./../../../src/pages/docs/widget/index.tsx" /* webpackChunkName: "component---src-pages-docs-widget-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internal-annuity-call-form-index-tsx": () => import("./../../../src/pages/internal/annuity-call-form/index.tsx" /* webpackChunkName: "component---src-pages-internal-annuity-call-form-index-tsx" */),
  "component---src-pages-tests-tsx": () => import("./../../../src/pages/tests.tsx" /* webpackChunkName: "component---src-pages-tests-tsx" */),
  "component---src-pages-videos-[product]-[slug]-index-tsx": () => import("./../../../src/pages/videos/[product]/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-videos-[product]-[slug]-index-tsx" */),
  "component---src-templates-form-form-template-tsx": () => import("./../../../src/templates/form/form.template.tsx" /* webpackChunkName: "component---src-templates-form-form-template-tsx" */),
  "component---src-templates-generic-generic-template-tsx": () => import("./../../../src/templates/generic/generic.template.tsx" /* webpackChunkName: "component---src-templates-generic-generic-template-tsx" */)
}

