import { createPattern, escapeSelector } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern(
        'flex-(row|column)(?:-(reverse)){0,1}', 
        {breakpoints: true
    });

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, value, reverse] = props;
    // console.log(props);
    const selector = escapeSelector(className);
    

    const rule = {
        [selector]: {
            flexDirection: `${value}${reverse? '-reverse' : ''}`
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };