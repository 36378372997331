import { createPattern, escapeSelector } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern('grid-flow-(col|row)(?:-(dense)){0,1}', {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, direction, dense] = props;
    // console.log(props);
    const selector = escapeSelector(className);

    enum DirectionMap {
        'col' = 'column',
        'row' = 'row'
    }

    const rule = {
        [selector]: {
            gridAutoFlow: `${DirectionMap[direction]}${dense ? ' dense' : ''}`,
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };