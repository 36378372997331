import { createPattern, escapeSelector } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern('cursor-(default|pointer)', {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, value] = props;
    // console.log(props);
    const selector = escapeSelector(className);

    const rule = {
        [selector]: {
            cursor: value
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };