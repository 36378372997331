import { createPattern, escapeSelector, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType } from '../types';

// Pattern `(?:(hover|active|focus|visited)\:){0,1}(-){0,1}(translate|scale)-(?:(x|y|z)-){0,1}(${numValuePattern}){0,1}((?:${unitPattern})){0,1}`
export const pattern = createPattern(`(?:(hover|active|focus|visited)\:){0,1}transition-([a-z\-]+)-(${numValuePattern}(?:ms|s))-([a-zA-Z]+)(?:-(${numValuePattern}(?:ms|s))){0,1}(\!){0,1}`, {breakpoints: true});

export const EasingMap = {
    'ease': 'ease',
    'easeIn': 'ease-in',
    'easeOut': 'ease-out',
    'easeInOut': 'ease-in-out',
    'easeOutExpo': 'cubic-bezier(0.16, 1, 0.3, 1)',
    'easeInOutQuint': 'cubic-bezier(0.83, 0, 0.17, 1)',
    'easeInOutCubic': 'cubic-bezier(0.65, 0, 0.35, 1)',
    'snap': 'cubic-bezier(0.34, 1.56, 0.64, 1)',

};

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, state, property, duration, easing, delay, important] = props; // unit can also be "auto" | "unset"
    // console.log(props);
    const selector = escapeSelector(className);


    const rule = {
        [`${selector}${state ? `:${state}` : ''}`]: {
            transition: `${property} ${duration} ${EasingMap[easing]}${delay ? ` ${delay}` : ''}${important ? ' !important' : ''}`,
            backfaceVisibility: 'hidden',
            ['& *']: {
                backfaceVisibility: 'hidden'
            }
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };