import config from '../../config';
import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern(`gap-(?:(x|y)-)(${numValuePattern})(${unitPattern})`, {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, property, rawValue, unit] = props;
    // console.log(props);
    
    const selector = escapeSelector(className);
    const value = parseFloat(rawValue);
    

    enum PropertyMap {
        'x' = 'column-gap',
        'y' = 'row-gap'
    }
    

    const rule = {
        [selector]: {
            [PropertyMap[property]]: calculateUnitValue(value, unit),
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };