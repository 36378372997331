import { createPattern, escapeSelector } from '../../utils';
import { ClassObjectType } from '../../types';

// Pattern
export const pattern = createPattern(`text-(normal|italic|inherit|initial|unset)(\!){0,1}`, {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, value, important] = props;
    // console.log(props);
    
    const selector = escapeSelector(className);

    const rule = {
        [selector]: {
            fontStyle: `${value}${important ? ' !important' : ''}`,
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };