import { calculateUnitValue, createPattern, escapeSelector, numValuePattern, unitPattern } from '../utils';
import { ClassObjectType } from '../types';

// Pattern
export const pattern = createPattern(`(?:(hover|active|focus|visited)\:){0,1}border-(?:(top|right|bottom|left)-){0,1}(${numValuePattern})(${unitPattern})(?:-(solid|dotted|dashed))(\!){0,1}`, {breakpoints: true});

// Resolver
export const rules = (props): ClassObjectType => {
    const [className, breakpoint, state, property, rawValue, unit, style, important] = props;
    // console.log(props);
    
    const selector = escapeSelector(className);
    const value = parseFloat(rawValue);
    

    const PropertyMap = {
        '': 'border',
        'top': 'border-top',
        'right': 'border-right',
        'bottom': 'border-bottom',
        'left': 'border-left'
    }
    

    const rule = {
        [`${selector}${state ? `:${state}` : ''}`]: {
            [`${PropertyMap[property]}-style`]: `${style}${important ? ' !important' : ''}`,
            [`${PropertyMap[property]}-width`]: `${calculateUnitValue(value, unit)}${important ? ' !important' : ''}`,
        }
    };

    return {
        breakpoint,
        rule
    };
    
}

export default { pattern, rules };