import WebFont from 'webfontloader';

const WebFontConfig = {
    typekit: {
        id: 'gqa6msf',
        async: true
    },
    active: () => {
        // window.dataLayer = window.dataLayer || [];
        // // console.log('GTM: WebFontConfig', 'FontsActive');

        setTimeout(() => {
            //console.log('GTM: PageReady');
            window?.dataLayer?.push({ 'event': 'PageReady' });
        }, 300);
    }
}


WebFont.load(WebFontConfig);