import { ConfigType } from "./types";

const config = {
    prefix: 'lr',
    unit: 'rem',
    spacer: 1,
    gutter: 3,
    fontFamily: `macha, "Calibri", sans-serif`,
    headingFontFamily: `geller-headline, "Times New Roman", serif`,
    breakpoints: {
        '': { media: 0, container: '85vw', base: 10 },
        xxs: { media: 320, container: '85vw', base: 10 },
        xs: { media: 375, container: '85vw', base: 10 },
        sm: { media: 425, container: '85vw', base: 10 },
        smd: { media: 636, container: '85vw', base: 10 },
        md: { media: 768, container: '90vw', base: 9 },
        '^md': { media: 1024, height: 780, container: '90vw', base: 8 },
        lg: { media: 1024, container: '100rem', base: 9 },
        '^lg': { media: 1024, height: 920, container: '100rem', base: 9 },
        xl: { media: 1280, container: 1210, base: 9 },
        xxl: { media: 1440, container: 1210, base: 10 },
    },
    colors: {
        text: {
            main: '#222426'
        },
        heading: {
            main: '#222426'
        },
        primary: {
            main: 800,
            800: ['#222426', '#fff'],
            700: ['#2F3234', '#fff'],
        },
        secondary: {
            main: 200,
            100: ['#DEE4E0', '#222426'],
            200: ['#DBE3DE', '#222426'],
            300: ['#BFD3C6', '#222426'],
        },
        accent: {
            main: 700,
            700: ['#5ED28A', '#fff'],
            800: ['#51C17B', '#fff'],
        },
        grey: {
            main: 200,
            50: ['#FAFAFA', '#222426'],
            100: ['#FCFCFC', '#222426'],
            200: ['#F8F8F8', '#222426'],
            300: ['#F4F3F3', '#222426'],
            400: ['#E2E2E2', '#222426'],
            600: ['#b9b9b9', '#222426'],
            700: ['#909191', '#222426'],
            800: ['#84898E', '#222426'],
            850: ['#707376', '#222426'],
            900: ['#606569', '#fff'],
            
        },
        white: {
            main: 500,
            500: ['#fff', '#222426']
        },
        error: {
            main: 500,
            500: ['#DE623B', '#fff']
        },
        success: {
            main: 200,
            100: ['#DEE4E0', '#709F90'],
            200: ['#DBE3DE', '#709F90'],
        },
        transparent: {
            main: 500,
            500: ['rgba(0,0,0,0)', 'rgba(0,0,0,0)']
        },
        shadow: {
            main: 900,
            500: ['rgba(20, 20, 20, 0.04)', '#fff'],
            600: ['rgba(20, 20, 20, 0.06)', '#fff'],
            700: ['rgba(20, 20, 20, 0.05)', '#fff'],
            800: ['rgba(20, 20, 20, 0.3)', '#fff'],
            900: ['rgba(20, 20, 20, 0.4)', '#fff'],
        }
    }
}

export default config;